import styles from "./header.module.scss";
import MeritoDarkLogo from "../../assets/images/MeritoLogoDark.svg";
import Buttons, { ButtonStyles } from "../buttons/Buttons";
import useScrollDirection from "./useScrollDirection";
import { useEffect, useState } from "react";
import CalendlyPopup from "../calendlyPopup/CalendlyPopup";
import { useLocation, useNavigate } from "react-router-dom";

export type PrefilledCalendly = {
	name: string;
	email: string;
};

const Header = ({ showForm, setShowForm }) => {
	const scrollDirection = useScrollDirection();
	const [showCalendly, setShowCalendly] = useState(false);
	const [prefillAnswer, setPrefillAnswer] = useState<PrefilledCalendly>({
		name: "",
		email: "",
	});
	const navigate = useNavigate();

	const [showCreateAccount, setShowCreateAccount] = useState(true);

	const { pathname } = useLocation();

	// Track scroll position to toggle "Create Account" button
	useEffect(() => {
		const heroSection = document.getElementById("hero-section");

		const checkHeroVisibility = () => {
			if (heroSection) {
				const rect = heroSection.getBoundingClientRect();
				const windowHeight = window.innerHeight;

				// Calculate the visible part of the HeroSection
				const visibleHeight = Math.min(rect.bottom, windowHeight) - Math.max(rect.top, 0);
				const heroSectionHeight = rect.height;

				// Calculate the percentage of the HeroSection that is visible
				// Determine visibility threshold based on screen width
				const isMobileView = window.innerWidth <= 760;
				const visibilityThreshold = isMobileView ? 30 : 70; // 30% for mobile, 70% for desktop

				// Calculate the percentage of the HeroSection that is visible
				const visiblePercentage = (visibleHeight / heroSectionHeight) * 100;

				// Show button when scrolled past HeroSection OR Hide button when in HeroSection
				setShowCreateAccount(
					visiblePercentage < visibilityThreshold || window.location.pathname !== "/"
				);
			}
		};

		// Initial check on mount
		checkHeroVisibility();

		// Add scroll event listener
		const handleScroll = () => {
			checkHeroVisibility();
		};

		window.addEventListener("scroll", handleScroll);

		// Cleanup on component unmount
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, [window.location.pathname]);

	// Smooth scroll to the HeroSection
	const handleScrollToHeroSection = () => {
		const heroSection = document.getElementById("hero-section");
		const header = document.getElementById("custom-header");

		if (heroSection && header) {
			const headerHeight = header.offsetHeight; // Get header height
			const heroSectionTop = heroSection.getBoundingClientRect().top + window.scrollY; // Get top position of HeroSection

			// Scroll to the HeroSection minus the header height
			window.scrollTo({
				top: heroSectionTop - headerHeight, // Adjust for header height
				behavior: "smooth",
			});
		}
	};

	return (
		<nav
			id="custom-header"
			className={`${styles.header} ${scrollDirection === "down" ? styles.hide : ""}`}
		>
			<div
				className={styles.brandContainer}
				onClick={() => {
					navigate("/");
				}}
			>
				<img src={MeritoDarkLogo} alt="Merito" />
			</div>

			<div className={styles.rightContainer}>
				{showCreateAccount && pathname === "/" ? (
					<Buttons
						type={ButtonStyles.SECONDARY}
						onClick={() => {
							handleScrollToHeroSection();
							setShowForm(true);
						}}
					>
						<div className={styles.btnContainer}>Sign Up</div>
					</Buttons>
				) : null}
				<CalendlyPopup
					showPopup={showCalendly}
					setShowPopup={setShowCalendly}
					prefillValues={prefillAnswer}
				/>
			</div>
		</nav>
	);
};

export default Header;

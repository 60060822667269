import styles from "./GridCard.module.scss";

type Props = {
	imgAsset: any;
	text: React.ReactNode;
};

const GridCard = ({ imgAsset, text }: Props) => {
	return (
		<div className={styles.cardContainer}>
			<div className={styles.imgContainer}>
				<img src={imgAsset} alt="" />
			</div>
			<div className={styles.textSection}>{text}</div>
		</div>
	);
};

export default GridCard;

import { useEffect } from "react";
import Fifthsection from "../components/fifthSection/FifthSection";
import FourthSection from "../components/fourthSection/FourthSection";
import HeroSection from "../components/heroSection/HeroSection";
import SecondSection from "../components/SecondSection/SecondSection";
import SixthSection from "../components/SixthSection/SixthSection";
import BrandsSection from "../components/BrandsSection/BrandsSection";
import { useOutletContext } from "react-router-dom";

const Home = () => {
	useEffect(() => {
		window.scrollTo(0, 0); // Scrolls to the top of the page
	}, []);
	const { showForm, setShowForm } = useOutletContext<any>();

	return (
		<>
			<HeroSection showForm={showForm} setShowForm={setShowForm} />
			<SecondSection />
			<FourthSection />
			<BrandsSection />
			<Fifthsection />
			<SixthSection />
		</>
	);
};

export default Home;

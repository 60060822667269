import { ParagraphType } from "../../data";
import styles from "./OrderedParagraph.module.scss";

type Props = {
	subTitle: React.ReactNode;
	subHeading: React.ReactNode;
	listHeading: React.ReactNode;
	type: ParagraphType;
	content: React.ReactNode[];
};

const OrderedParagraph = ({ subTitle, type, content, listHeading, subHeading }: Props) => {
	return (
		<div className={styles.container}>
			{subTitle ? <div className={styles.title}>{subTitle}</div> : null}
			{subHeading ? <div className={styles.subHeading}>{subHeading}</div> : null}
			<div className={styles.listContainer}>
				{listHeading ? <div className={styles.listTitle}>{listHeading}</div> : null}
				{type === ParagraphType.unorderedList ? (
					<ul className={styles.list}>
						{content.map((item) => (
							<li className={styles.listItem}>{item}</li>
						))}
					</ul>
				) : (
					<ol className={styles.list}>
						{content.map((item) => (
							<li className={styles.listItem}>{item}</li>
						))}
					</ol>
				)}

				{/* <ol className={styles.list}>
                    <li className={styles.listItem}>To personalize your experience (your information helps us to better respond to your individual needs) To improve our website/app (we continually strive to improve our website/app offerings based on the information and feedback we receive from you) </li>
                    <li className={styles.listItem}>To improve customer service (your information helps us to more effectively respond to your customer service requests and support needs)</li>
                    <li className={styles.listItem}>To process transactions </li>
                    <li className={styles.listItem}>To administer a contest, promotion, survey or other site feature </li>
                    <li className={styles.listItem}>To send periodic emails </li>
                </ol> */}
			</div>
		</div>
	);
};

export default OrderedParagraph;

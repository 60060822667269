import { useState } from "react";
import { Dialog, MenuItem, Select, styled } from "@mui/material";
import { Field, Form } from "react-final-form";
import Buttons, { ButtonStyles } from "../buttons/Buttons";
import CustomTextField from "../Footer/components/TextField/CustomTextfield";
import {
	customEmailValidation,
	domainNameValidation,
	mobileNumberValidation,
	reqiredValidation,
} from "../Footer/components/validation/validationFunctions";
import styles from "./EarlyAccess.module.scss";
import axios from "axios";
import { AiOutlineClose as CloseIcon } from "react-icons/ai";
import { FORM_ERROR } from "final-form";
import { URLSearchParams } from "url";
import { btnSelectType } from "../heroSection/SignUpForm";

export const CustomDialog = styled(Dialog)(({ theme }) => ({
	"& .MuiDialog-paper": {
		borderRadius: "15px",
	},
}));

export interface ScheduleDemoAnswers {
	customerType: string;
	name: string;
	emailId: string;
	phoneNumber: string;
	companyWebsite: string;
	referredFrom: string;
	clickedClose?: Boolean;
	action?: btnSelectType;
}

type Props = {
	isOpen: boolean;
	handleCloseModal: () => void;
	setIsOpen: (value: boolean) => void;
	handleSave: (values) => void;
	searchParams?: URLSearchParams;
	setSearchParams?: (value: URLSearchParams) => void;
};

const EarlyAccess = ({
	isOpen,
	setIsOpen,
	handleCloseModal,
	handleSave,
	searchParams,
	setSearchParams,
}: Props) => {
	const [loading, setLoading] = useState(false);
	let formValues = {};
	const onSubmit = async (values) => {
		// console.log(values);
		let errors = {
			value: false,
			message: {},
		};
		let submittedValues: ScheduleDemoAnswers = {
			customerType: values["customerType"],
			name: `${values["firstName"]} ${values["lastName"]}`,
			emailId: values["emailId"],
			phoneNumber: values["phoneNumber"],
			companyWebsite: values["companyWebsite"],
			referredFrom: values["referredFrom"],
			clickedClose: false,
		};
		setLoading(true);
		try {
			const data = await axios
				.post(`${process.env.REACT_APP_API_URL}/api/clickmerito/leads`, submittedValues)
				.then((res) => res.data);
			setLoading(false);
			handleSave(values);
			clearSearchParams();
		} catch (error) {
			setLoading(false);
			clearSearchParams();
			errors = {
				value: true,
				message: {
					[FORM_ERROR]: "Something went wrong",
				},
			};
			console.log(error);
		}
		if (errors.value) {
			// console.log(errors);
			return errors.message;
		}
	};

	const clearSearchParams = () => {
		let earlyAccessParams = searchParams.get("popup");
		if (earlyAccessParams === "earlyaccess") {
			let oldSearchParams = searchParams;
			oldSearchParams.delete("popup");
			setSearchParams(oldSearchParams);
		}
	};

	const runOnClose = async () => {
		if (
			formValues["firstName"] &&
			formValues["emailId"] &&
			!customEmailValidation(formValues["emailId"])
		) {
			console.log("form values", formValues);
			let submittedValues: ScheduleDemoAnswers = {
				customerType: formValues["customerType"],
				name: `${formValues["firstName"]} ${formValues["lastName"]}`,
				emailId: formValues["emailId"],
				phoneNumber: formValues["phoneNumber"],
				companyWebsite: formValues["companyWebsite"],
				referredFrom: formValues["referredFrom"],
				clickedClose: true,
			};
			try {
				const data = await axios
					.post(`${process.env.REACT_APP_API_URL}/api/clickmerito/leads`, submittedValues)
					.then((res) => res.data);
				// setLoading(false);
				// handleSave(values);
				// clearSearchParams();
			} catch (error) {
				// setLoading(false);
				// clearSearchParams();
				console.log(error);
			}
		}
	};

	return (
		<CustomDialog
			fullWidth={true}
			maxWidth={"md"}
			open={isOpen}
			onClose={() => {
				runOnClose();
				handleCloseModal();
				clearSearchParams();
			}}
		>
			<div className={styles.dialogContainer}>
				<div className={styles.formMainContainer}>
					<div className={styles.headerTitle}>
						Get your Early Access invite
						<div className={styles.subTitle}>
							We’ll set up your Merito account on a 15 minute call
						</div>
					</div>
					<Form
						onSubmit={(values) => onSubmit(values)}
						render={({
							handleSubmit,
							pristine,
							hasValidationErrors,
							submitError,
							values,
						}) => {
							formValues = values;
							return (
								<>
									<form onSubmit={handleSubmit}>
										<div className={styles.formSection}>
											<Field
												name="customerType"
												validate={(values) =>
													reqiredValidation(values, "Required")
												}
											>
												{(props) => {
													return (
														<div
															className={
																styles.selectTextFieldContainer
															}
														>
															<div className={styles.label}>
																Are you a brand or an agency? *
															</div>
															<Select
																fullWidth={true}
																{...props.input}
																value={props.input.value}
																variant={"standard"}
																onChange={props.input.onChange}
																style={{
																	fontSize: "1.5rem",
																	color: "#666666",
																}}
															>
																<MenuItem value={"Brand"}>
																	Brand
																</MenuItem>
																<MenuItem value={"Agency"}>
																	Agency
																</MenuItem>
															</Select>
														</div>
													);
												}}
											</Field>
											<div className={styles.flexField}>
												<Field
													name="firstName"
													validate={(values) =>
														reqiredValidation(values, "First Name")
													}
												>
													{(props) => {
														return (
															<CustomTextField
																{...props.input}
																maxLength={15}
																label={"First Name *"}
																value={props.input.value}
																onChange={props.input.onChange}
																// placeholder={"Name"}
																error={
																	props.meta.error &&
																	props.meta.touched
																		? props.meta.error
																		: props.meta.submitError
																			? props.meta.submitError
																			: null
																}
															/>
														);
													}}
												</Field>
												<Field
													name="lastName"
													maxLength={15}
													validate={(values) =>
														reqiredValidation(values, "Last Name")
													}
												>
													{(props) => {
														return (
															<CustomTextField
																{...props.input}
																maxLength={15}
																label={"Last Name *"}
																value={props.input.value}
																onChange={props.input.onChange}
																// placeholder={"Name"}
																error={
																	props.meta.error &&
																	props.meta.touched
																		? props.meta.error
																		: props.meta.submitError
																			? props.meta.submitError
																			: null
																}
															/>
														);
													}}
												</Field>
											</div>
											<div className={styles.flexField}>
												<Field
													name="emailId"
													validate={customEmailValidation}
												>
													{(props) => {
														return (
															<CustomTextField
																{...props.input}
																maxLength={50}
																label={"Email Id *"}
																value={props.input.value}
																onChange={props.input.onChange}
																// placeholder={"Email Id"}
																error={
																	props.meta.error &&
																	props.meta.touched
																		? props.meta.error
																		: props.meta.submitError
																			? props.meta.submitError
																			: null
																}
															/>
														);
													}}
												</Field>

												<Field
													name="phoneNumber"
													validate={(values) => {
														if (!values) return "";
														else return mobileNumberValidation(values);
													}}
												>
													{(props) => {
														return (
															<CustomTextField
																{...props.input}
																maxLength={10}
																label={"Phone Number"}
																value={props.input.value}
																onChange={props.input.onChange}
																// placeholder={"Phone Number"}
																error={
																	props.meta.error &&
																	props.meta.touched
																		? props.meta.error
																		: props.meta.submitError
																			? props.meta.submitError
																			: null
																}
															/>
														);
													}}
												</Field>
											</div>

											<div className={styles.flexBottomField}>
												{/* <Field name='companyName' validate={(values) => reqiredValidation(values, "First Name")}>
                                            {props => {
                                                return <CustomTextField {...props.input} maxLength={25} label={"Company Name *"} value={props.input.value} onChange={props.input.onChange}
                                                    // placeholder={"Name"} 
                                                    error={props.meta.error && props.meta.touched ? props.meta.error : props.meta.submitError ? props.meta.submitError : null} />
                                            }}
                                        </Field> */}

												<Field
													name="companyWebsite"
													maxLength={50}
													validate={(values) =>
														domainNameValidation(
															values,
															"Company Website"
														)
													}
												>
													{(props) => {
														return (
															<CustomTextField
																{...props.input}
																maxLength={50}
																label={"Company Website *"}
																value={props.input.value}
																onChange={props.input.onChange}
																// placeholder={"Name"}
																error={
																	props.meta.error &&
																	props.meta.touched
																		? props.meta.error
																		: props.meta.submitError
																			? props.meta.submitError
																			: null
																}
															/>
														);
													}}
												</Field>

												{/* <Field name='referredFrom'>
                                            {props => {
                                                return <div className={styles.selectTextFieldContainer}>
                                                    <div className={styles.label}>How did you come across Merito?</div>
                                                    <Select
                                                        fullWidth={true}
                                                        {...props.input}
                                                        value={props.input.value}
                                                        variant={"standard"}
                                                        onChange={props.input.onChange}
                                                        style={{ fontSize: "1.5rem", color: "#666666", }}
                                                    >
                                                        <MenuItem value={"LinkedIn"}>LinkedIn</MenuItem>
                                                        <MenuItem value={"Twitter"}>Twitter</MenuItem>
                                                        <MenuItem value={"Google"}>Google</MenuItem>
                                                        <MenuItem value={"Google"}>Email</MenuItem>
                                                        <MenuItem value={"Through Someone you know"}>Someone in your network</MenuItem>
                                                    </Select>
                                                </div>
                                            }}
                                        </Field> */}
											</div>
											{/* <div className={styles.halfWidthContainer}>
                                        <Field name='referredFrom'>
                                            {props => {
                                                return <div className={styles.selectTextFieldContainer}>
                                                    <div className={styles.label}>How did you come across Merito?</div>
                                                    <Select
                                                        fullWidth={true}
                                                        {...props.input}
                                                        value={props.input.value}
                                                        variant={"standard"}
                                                        onChange={props.input.onChange}
                                                        style={{ fontSize: "1.5rem", color: "#666666", }}
                                                    >
                                                        <MenuItem value={"LinkedIn"}>LinkedIn</MenuItem>
                                                        <MenuItem value={"Twitter"}>Twitter</MenuItem>
                                                        <MenuItem value={"Google"}>Google</MenuItem>
                                                        <MenuItem value={"Through Someone you know"}>Through Someone you know</MenuItem>
                                                    </Select>
                                                </div>
                                            }}
                                        </Field>
                                    </div> */}
											{/* <Field name='requirements'>
                                        {props => {
                                            return <CustomTextField {...props.input} maxLength={400} label={"What kind of analytics are you looking to give your customers?"} maxRows={3} minRows={1} isTextArea={true} value={props.input.value} onChange={props.input.onChange}
                                                // placeholder={"Message"} 
                                                error={props.meta.error && props.meta.touched ? props.meta.error : props.meta.submitError ? props.meta.submitError : null} />
                                        }}
                                    </Field> */}

											<div className={styles.btnMainContainer}>
												<Buttons
													type={ButtonStyles.PRIMARY}
													loading={loading}
													disabled={
														pristine || loading || hasValidationErrors
													}
												>
													<div className={styles.btnContainer}>
														Schedule a Demo
													</div>
												</Buttons>

												{submitError && (
													<div className={styles.formError}>
														{submitError}
													</div>
												)}
											</div>
										</div>
									</form>
								</>
							);
						}}
					/>
				</div>

				<div className={styles.closeBtnContainer}>
					<div
						className={styles.closeBtn}
						onClick={() => {
							runOnClose();
							handleCloseModal();
							clearSearchParams();
						}}
					>
						<CloseIcon />
					</div>
				</div>
			</div>
		</CustomDialog>
	);
};

export default EarlyAccess;

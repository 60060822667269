import { ParagraphContentType, ParagraphType } from "../../data";
import OrderedParagraph from "./OrderedParagraph";
import styles from "./Paragraph.module.scss";

type Props = {
	title: React.ReactNode;
	paragraphs: ParagraphContentType[];
};

const Paragraph = ({ title, paragraphs }: Props) => {
	return (
		<div className={styles.container}>
			{title ? <div className={styles.title}>{title}</div> : null}
			<div className={styles.paraContainer}>
				{paragraphs.map((item, index) => {
					return (
						<>
							{item.type === ParagraphType.paragraph ? (
								<>
									{item.subTitle ? (
										<div className={styles.paraTitle}>{item.subTitle}</div>
									) : null}
									{item.subHeading ? (
										<div className={styles.subHeading}>{item.subHeading}</div>
									) : null}
									<div className={styles.paragraph}>
										{Array.isArray(item.content) ? (
											item.content.map((item, index) => {
												return (
													<div className={styles.paragraph}>{item}</div>
												);
											})
										) : (
											<div className={styles.paragraph}>{item.content}</div>
										)}
									</div>
								</>
							) : (
								<OrderedParagraph
									subTitle={item.subTitle}
									subHeading={item.subHeading}
									listHeading={item.listHeading}
									type={item.type}
									content={item.content as React.ReactNode[]}
								/>
							)}
						</>
					);
				})}
			</div>
		</div>
	);
};

export default Paragraph;

import styles from "./BrandsSection.module.scss";
import zlade from "../../assets/images/brands/logo_zlade.webp";
import assembly from "../../assets/images/brands/logo_assembly.webp";
import bluetea from "../../assets/images/brands/logo_bluetea.webp";
import bummer from "../../assets/images/brands/logo_bummer.webp";
import friendsdiaper from "../../assets/images/brands/logo_friendsdiaper.webp";
import godesi from "../../assets/images/brands/logo_godesi.webp";
import goodbug from "../../assets/images/brands/logo_goodbug.webp";
import peesafe from "../../assets/images/brands/logo_peesafe.webp";
import saadaa from "../../assets/images/brands/logo_saadaa.webp";
import whatsupwellness from "../../assets/images/brands/logo_whatsupwellness.webp";
import winston from "../../assets/images/brands/logo_winston.webp";
import zavya from "../../assets/images/brands/logo_zavya.webp";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Mousewheel } from "swiper";

const allBrands = [
	{ brandName: "zlade", img: zlade },
	{ brandName: "assembly", img: assembly },
	{ brandName: "bluetea", img: bluetea },
	{ brandName: "bummer", img: bummer },
	{ brandName: "friendsdiaper", img: friendsdiaper },
	{ brandName: "godesi", img: godesi },
	{ brandName: "goodbug", img: goodbug },
	{ brandName: "peesafe", img: peesafe },
	{ brandName: "saadaa", img: saadaa },
	{ brandName: "whatsupwellness", img: whatsupwellness },
	{ brandName: "winston", img: winston },
	{ brandName: "zavya", img: zavya },
];

const BrandsSection = () => {
	return (
		<section className={styles.brandsSection}>
			<div className={styles.topContainer}>
				<div className={styles.title}>Trusted by 250+ D2C Brands</div>
			</div>
			<div className={styles.brandCarouselContainer}>
				<Swiper
					modules={[Mousewheel, Autoplay]}
					loop={true}
					breakpoints={{
						320: {
							slidesPerView: 2,
							spaceBetween: 200,
						},
						360: {
							slidesPerView: 2,
							spaceBetween: 160,
						},
						960: {
							slidesPerView: 4,
							spaceBetween: 48,
						},
						1152: {
							slidesPerView: 5,
							spaceBetween: 120,
						},
						1309: {
							slidesPerView: 5,
							spaceBetween: 0,
						},
						1440: {
							slidesPerView: 6,
							spaceBetween: 100,
						},
					}}
					centeredSlides={true}
					slidesPerView={1.5}
					direction="horizontal"
					loopFillGroupWithBlank={true}
					autoplay={{
						delay: 1000,
						disableOnInteraction: false,
						pauseOnMouseEnter: true,
					}}
					mousewheel={{
						forceToAxis: true,
					}}
				>
					{allBrands.map((item) => {
						return (
							<SwiperSlide key={item.brandName}>
								<div className={styles.brandCardRootContainer}>
									<div className={styles.imgContainer}>
										<img src={item.img} alt={item.brandName} />
									</div>
								</div>
							</SwiperSlide>
						);
					})}
				</Swiper>
			</div>
		</section>
	);
};

export default BrandsSection;

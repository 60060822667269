import { useState, useEffect } from "react";
import { Stepper, Step } from "@mui/material";
import styles from "./FifthSection.module.scss";
import { styled } from "@mui/material/styles";
import StepLabel, { stepLabelClasses } from "@mui/material/StepLabel";
import { stepClasses } from "@mui/material/Step";

import StepConnector, { stepConnectorClasses } from "@mui/material/StepConnector";

const steps = [
	<>
		Create your brand’s<br></br>Merito account
	</>,
	<>
		Securely link your<br></br>sales and ad accounts
	</>,
	<>
		Invite your team and<br></br>start collaborating!
	</>,
];

const CustomizedConnector = styled(StepConnector)(({ theme }) => ({
	[`&.${stepConnectorClasses.horizontal}.${stepConnectorClasses.root}`]: {
		marginLeft: 50,
		marginTop: 48,
	},
	[`&.${stepConnectorClasses.vertical}.${stepConnectorClasses.root}`]: {
		marginLeft: 65,

		[theme.breakpoints.down(600)]: {
			marginLeft: 50,
		},
	},
	[`&.${stepConnectorClasses.horizontal} .${stepConnectorClasses.line}`]: {
		height: 20,
		width: "80%",
		borderColor: "#fff",
		borderRadius: 1,
		borderWidth: 5,
		borderBottomColor: "transparent",
		borderRightColor: "transparent",
		borderLeft: "transparent",
		borderStyle: "dashed",
	},
	[`&.${stepConnectorClasses.vertical} .${stepConnectorClasses.line}`]: {
		height: 50,
		borderColor: "#fff",
		borderRadius: 6,
		borderWidth: 5,
		borderTopColor: "transparent",
		borderRightColor: "transparent",
		borderBottomColor: "transparent",
		borderStyle: "dashed",

		[theme.breakpoints.down(600)]: {
			height: 60,
		},
	},
}));

const CustomLabel = styled(StepLabel)(({ theme }) => ({
	[`&.${stepLabelClasses.root}`]: {
		padding: 20,
		color: "#fff",
	},
	[`$.${stepLabelClasses.alternativeLabel}`]: {
		color: "#fff",
		backgroundColor: "#fff",
	},
}));

const Fifthsection = () => {
	const [width, setWidth] = useState(window.innerWidth);
	const updateDimensions = () => {
		setWidth(window.innerWidth);
	};
	useEffect(() => {
		window.addEventListener("resize", updateDimensions);
		return () => window.removeEventListener("resize", updateDimensions);
	}, []);

	const CustomStepIcon = (props: any, stepIndex: number) => {
		return (
			<div className={styles.labelContainer}>
				<div className={`${styles.stepLabels} ${styles.active}`}>{stepIndex + 1}</div>
			</div>
		);
	};

	return (
		<section className={styles.main}>
			<div className={styles.container}>
				<div className={styles.textContainer}>How Does It Work?</div>
				<div className={styles.stepperContainer}>
					<Stepper
						className={styles.stepper}
						orientation={width < 600 ? "vertical" : "horizontal"}
						connector={<CustomizedConnector />}
						alternativeLabel={width < 600 ? false : true}
					>
						{steps.map((label, index) => {
							const stepProps: { completed?: boolean } = {};
							const labelProps: {
								optional?: React.ReactNode;
							} = {};
							return (
								<Step key={index} {...stepProps}>
									<CustomLabel
										StepIconComponent={(props) => CustomStepIcon(props, index)}
										{...labelProps}
									>
										<div className={styles.stepLabel}>{label}</div>
									</CustomLabel>
								</Step>
							);
						})}
					</Stepper>

					<div className={styles.bottomText}>Go Live in Just 10 Minutes</div>
				</div>
			</div>
		</section>
	);
};

export default Fifthsection;

import { TextField } from "@mui/material";
import { ChangeEventHandler } from "react";
import styles from "./CustomTextField.module.scss";
import { styled } from "@mui/material/styles";
import "./textfield.css";

type Props = {
	value: string;
	onChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
	placeholder?: string;
	type?: string;
	error?: string;
	name?: string;
	style?: React.CSSProperties;
	disabled?: boolean;
	maxLength?: number;
	label?: string;
	size?: string;
	isTextArea?: boolean;
	maxRows?: number;
	minRows?: number;
	[x: string]: any;
};

const OverrideTextfield = styled(TextField)(({ theme }) => ({
	"& .Mui-error": {
		fontSize: "1rem",
	},
	"& .MuiInput-root": {
		color: "#212121",
		"& input .Mui-focused ": {
			borderColor: "rgba(14, 13, 17, 0.3)",
		},
	},
	"& .MuiInputBase-input": {
		fontSize: "1.75rem",
		fontFamily: "Quicksand",
		fontWeight: 600,
		// Smaller screen
		[theme.breakpoints.down("sm")]: {
			fontSize: "1.125rem", // Customize the font-size for small screen sizes
		},
		// Medium-sized screens
		[theme.breakpoints.between("sm", "lg")]: {
			fontSize: "1.25rem",
		},
		// Below extra-large screen
		[theme.breakpoints.down("xl")]: {
			fontSize: "1.5rem",
		},
	},
}));

const CustomTextField = ({
	label,
	value,
	onChange,
	type,
	error,
	name,
	style,
	disabled,
	maxLength,
	size,
	isTextArea = false,
	maxRows = 2,
	minRows = 2,
	...restProps
}: Props) => {
	return (
		<div className={`${styles.inputContainer} ${styles[size]}`}>
			<div className={styles.label}>{label}</div>
			<OverrideTextfield
				{...restProps}
				value={value}
				inputProps={{ maxLength: maxLength }}
				multiline={isTextArea}
				maxRows={maxRows}
				minRows={minRows}
				fullWidth={true}
				onChange={onChange}
				variant="standard"
				size={"medium"}
				error={error ? true : false}
				helperText={error ? error : ""}
				sx={{ borderColor: "#cecece" }}
			/>
			{!error ? <p style={{ margin: 0 }}></p> : null}
		</div>
	);
};

export default CustomTextField;

import styles from "./buttons.module.scss";

export enum ButtonStyles {
	PRIMARY = "primary",
	OUTLINE = "outline",
	SECONDARY = "secondary",
}

type Props = {
	type: ButtonStyles;
	children: JSX.Element;
	onClick?: () => void;
	loading?: boolean;
	disabled?: boolean;
	[x: string]: any;
};

const Buttons = ({ type, children, onClick, loading, disabled, ...restProps }: Props) => {
	return (
		<button
			{...restProps}
			disabled={disabled}
			className={`${styles[type]} ${loading ? styles.loading : ""}`}
			onClick={onClick}
		>
			<div className={styles.buttonText}>{children}</div>
		</button>
	);
};

export default Buttons;

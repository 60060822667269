import GridCard from "./cards/GridCard";
import styles from "./SixthSection.module.scss";
import DashboardImg from "../../assets/images/InteractiveDashboard.svg";
import Reports from "../../assets/images/CustomReports.svg";
import AnalyticsEngine from "../../assets/images/AnalyticsEngine.svg";

const CardText = [
	<>Consolidate all clients in a single dashboard</>,
	<>Automated reporting for clients</>,
	<>Performance and retention marketing suggestions</>,
];

const SixthSection = () => {
	return (
		<section className={styles.main}>
			<div className={styles.container}>
				<div className={styles.textContainer}>
					How Does Merito Help Agencies <br></br>
					That Work With D2C Brands?
				</div>
				<div className={styles.cardGrid}>
					<GridCard imgAsset={DashboardImg} text={CardText[0]} />
					<GridCard imgAsset={Reports} text={CardText[1]} />
					<GridCard imgAsset={AnalyticsEngine} text={CardText[2]} />
				</div>
			</div>
		</section>
	);
};

export default SixthSection;

import { Swiper, SwiperSlide } from "swiper/react";
import styles from "./FourthSection.module.scss";
// import required modules
import "swiper/css";
import "swiper/css/pagination";
import "./swiper.css";
import { Mousewheel, Swiper as SwiperType } from "swiper";
import SwiperCard from "./swiperCard/SwiperCard";
import { useRef } from "react";
import { FaChevronLeft as LeftButton, FaChevronRight as RightButton } from "react-icons/fa";

const SwiperDetails = [
	{
		title: <>Take Faster Decisions</>,
		subTitle: <>40+ sales, acquisition and retention reports merging data across channels</>,
	},
	{
		title: <>Track Omnichannel Profitability</>,
		subTitle: <>Our P&L Tracker ensures your monthly P&L takes minutes and not days</>,
	},
	{
		title: <>Reduce Inefficient Ad Spends</>,
		subTitle: (
			<>Our proprietary UTM reader gives the most accurate ad campaign conversion tracking</>
		),
	},
	{
		title: <>Smarter Product Decisions</>,
		subTitle: (
			<>
				Extensive product level intelligence designed to ensure that you double down on your
				winners, and identify the losers
			</>
		),
	},
];

const FourthSection = () => {
	const navigationPrevRef = useRef(null);
	const navigationNextRef = useRef(null);
	const swiperRef = useRef<SwiperType>();

	return (
		<section className={styles.main}>
			<div className={styles.container}>
				<div className={styles.textContainer}>How Does Merito Help Your Brand?</div>
				<div className={styles.carouselContainer}>
					<Swiper
						initialSlide={0}
						navigation={{
							prevEl: navigationPrevRef.current,
							nextEl: navigationNextRef.current,
						}}
						grabCursor={true}
						slidesPerView={1}
						onBeforeInit={(swiper) => {
							swiperRef.current = swiper;
						}}
						spaceBetween={60}
						breakpoints={{
							850: {
								slidesPerView: 3,
								spaceBetween: 30,
							},
						}}
						loop={true}
						direction="horizontal"
						mousewheel={{
							forceToAxis: true,
						}}
						modules={[Mousewheel]}
						loopFillGroupWithBlank={true}
					>
						{SwiperDetails.map(({ subTitle, title }, index) => (
							<SwiperSlide key={index}>
								<SwiperCard title={title} subtitle={subTitle} />
							</SwiperSlide>
						))}
					</Swiper>
					<div className={styles.buttonsContainer}>
						<div
							ref={navigationPrevRef}
							onClick={() => swiperRef.current?.slidePrev()}
							className={styles.btnIconContainer}
						>
							<LeftButton />
						</div>
						<div
							ref={navigationNextRef}
							onClick={() => swiperRef.current?.slideNext()}
							className={styles.btnIconContainer}
						>
							<RightButton />
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default FourthSection;

import styles from "./SwiperCard.module.scss";

type Props = {
	title: React.ReactNode;
	subtitle: React.ReactNode;
};

const SwiperCard = ({ title, subtitle }: Props) => {
	return (
		<div className={styles.cardContainer}>
			<div className={styles.textContainer}>
				<div className={styles.title}>{title}</div>
				<hr />
				<div className={styles.subTitle}>{subtitle}</div>
			</div>
		</div>
	);
};

export default SwiperCard;

import styles from "./SecondSection.module.scss";
import meritoIntegrationsPng from "../../assets/images/integrationsDesktop.png";
import meritoIntegrationsPngMobile from "../../assets/images/integrationsMobile.png";
import { useEffect, useState } from "react";

const SecondSection = () => {
	const [width, setWidth] = useState(window.innerWidth);

	useEffect(() => {
		// Function to update the width state
		const handleResize = () => {
			setWidth(window.innerWidth);
		};

		// Add event listener to listen for window resize
		window.addEventListener("resize", handleResize);

		// Cleanup listener on component unmount
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return (
		<section className={styles.main}>
			<div className={styles.topContainer}>
				<h2 className={styles.title}>
					Actionable Insights by Merging Data Across Channels
				</h2>
			</div>

			<div className={styles.imgContainer}>
				<img
					src={width < 760 ? meritoIntegrationsPngMobile : meritoIntegrationsPng}
					alt=""
				/>
			</div>
		</section>
	);
};

export default SecondSection;

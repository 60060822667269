import { useState, useRef, useEffect } from "react";
import styles from "./Footer.module.scss";
import MeritoLightLogo from "../../assets/images/MeritoLogoLight.svg";
import Linkedin from "../../assets/images/Linkedin.svg";

import { Link } from "react-router-dom";
import Buttons, { ButtonStyles } from "../buttons/Buttons";
import { Form, Field } from "react-final-form";
import CustomTextField from "./components/TextField/CustomTextfield";
import {
	customEmailValidation,
	mobileNumberValidation,
	reqiredValidation,
} from "./components/validation/validationFunctions";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import { AiOutlineClose as CloseIcon } from "react-icons/ai";
import { CustomDialog } from "../EarlyAccess/EarlyAccess";

const Footer = () => {
	const [width, setWidth] = useState(window.innerWidth);

	const updateDimensions = () => {
		setWidth(window.innerWidth);
	};

	useEffect(() => {
		window.addEventListener("resize", updateDimensions);
		return () => window.removeEventListener("resize", updateDimensions);
	}, []);

	const [openModal, setOpenModal] = useState(false);
	const recaptchaRef = useRef(null);
	const [loading, setLoading] = useState(false);

	const handleCloseModal = () => {
		setOpenModal(false);
	};

	const onSubmit = async (values) => {
		console.log(values);
		try {
			if (recaptchaRef.current) {
				setLoading(true);
				setTimeout(() => {
					setLoading(false);
				}, 5000);
				const token = await recaptchaRef.current.executeAsync();
				console.log("token", token);
				recaptchaRef.current.reset();
				const verifiedData = await axios
					.post(`${process.env.REACT_APP_API_URL}/api/clickmerito/contactus`, {
						token: token,
						...values,
					})
					.then((res) => res.data);
				if (verifiedData) {
					console.log("verified data", verifiedData);
					setLoading(false);
				}
				setOpenModal(false);
			}
		} catch (error) {
			console.log(error);
			recaptchaRef.current.reset();
			setLoading(false);
			setOpenModal(false);
		}
	};

	const onCaptchaChange = (values) => {
		console.log(values);
	};

	return (
		<footer className={styles.main}>
			<div className={styles.container}>
				<div className={styles.brandingContainer}>
					<div className={styles.imgContainer}>
						<img src={MeritoLightLogo} alt="Merito" />
					</div>

					<div className={styles.socialMediaLinks}>
						<a
							href="https://www.linkedin.com/company/clickmerito/"
							target={"_blank"}
							className={styles.socialMedia}
							rel="noreferrer"
						>
							<img src={Linkedin} alt="" />
						</a>
					</div>
				</div>

				<div className={styles.emailContainer}>
					<a href="mailto:hello@clickmerito.com" className={styles.textContainer}>
						hello@clickmerito.com
					</a>

					<Link to="/privacypolicy">
						<div className={`${styles.textContainer} ${styles.underline}`}>
							Privacy Policy
						</div>
					</Link>

					<Link to="/termsofservice">
						<div className={`${styles.textContainer} ${styles.underline}`}>
							Terms of Service
						</div>
					</Link>

					<div
						className={`${styles.textContainer} ${styles.underline}`}
						onClick={() => setOpenModal(true)}
					>
						Contact us
					</div>
					<CustomDialog
						fullWidth={true}
						maxWidth={"md"}
						open={openModal}
						onClose={handleCloseModal}
					>
						<div className={styles.dialogContainer}>
							{width > 600 ? (
								<div className={styles.leftContainer}>
									<div className={styles.imgContainer}>
										<img src={MeritoLightLogo} alt="Merito" />
									</div>
									<div className={styles.textContainer}>
										<div className={styles.title}>
											Want to work with us? <br /> Want to share your
											feedback? <br /> Want to invest in us? <br /> Want to
											partner with us?
										</div>
										<br />
										<div className={styles.paragraph}>
											Fill up the form and our team will get in touch with you
											within 24 hours.
										</div>
									</div>
								</div>
							) : null}
							<div className={styles.rightContainer}>
								<div className={styles.title}>
									Love to hear from you, <br /> Get in touch 👋
								</div>

								<div className={styles.formContainer}>
									<Form
										onSubmit={(values) => onSubmit(values)}
										render={({
											handleSubmit,
											pristine,
											hasValidationErrors,
										}) => {
											return (
												<>
													<form onSubmit={handleSubmit}>
														<div className={styles.formSection}>
															<div className={styles.flexField}>
																<Field
																	name="name"
																	validate={(values) =>
																		reqiredValidation(
																			values,
																			"Name"
																		)
																	}
																>
																	{(props) => {
																		return (
																			<CustomTextField
																				{...props.input}
																				maxLength={25}
																				label={"Name *"}
																				value={
																					props.input
																						.value
																				}
																				onChange={
																					props.input
																						.onChange
																				}
																				// placeholder={"Name"}
																				error={
																					props.meta
																						.error &&
																					props.meta
																						.touched
																						? props.meta
																								.error
																						: props.meta
																									.submitError
																							? props
																									.meta
																									.submitError
																							: null
																				}
																			/>
																		);
																	}}
																</Field>
																<Field
																	name="emailId"
																	validate={customEmailValidation}
																>
																	{(props) => {
																		return (
																			<CustomTextField
																				{...props.input}
																				label="Email ID *"
																				value={
																					props.input
																						.value
																				}
																				onChange={
																					props.input
																						.onChange
																				}
																				error={
																					props.meta
																						.error &&
																					props.meta
																						.touched
																						? props.meta
																								.error
																						: props.meta
																									.submitError
																							? props
																									.meta
																									.submitError
																							: null
																				}
																			/>
																		);
																	}}
																</Field>
															</div>
															<div className={styles.flexField}>
																<Field
																	name="phoneNumber"
																	validate={(values) => {
																		if (!values) return "";
																		else
																			return mobileNumberValidation(
																				values
																			);
																	}}
																>
																	{(props) => {
																		return (
																			<CustomTextField
																				{...props.input}
																				maxLength={10}
																				label={
																					"Phone Number"
																				}
																				value={
																					props.input
																						.value
																				}
																				onChange={
																					props.input
																						.onChange
																				}
																				// placeholder={"Phone Number"}
																				error={
																					props.meta
																						.error &&
																					props.meta
																						.touched
																						? props.meta
																								.error
																						: props.meta
																									.submitError
																							? props
																									.meta
																									.submitError
																							: null
																				}
																			/>
																		);
																	}}
																</Field>

																<Field
																	name="purpose"
																	validate={(values) =>
																		reqiredValidation(
																			values,
																			"Purpose"
																		)
																	}
																>
																	{(props) => {
																		return (
																			<CustomTextField
																				{...props.input}
																				maxLength={40}
																				label={"Purpose *"}
																				value={
																					props.input
																						.value
																				}
																				onChange={
																					props.input
																						.onChange
																				}
																				// placeholder={"Email Id"}
																				error={
																					props.meta
																						.error &&
																					props.meta
																						.touched
																						? props.meta
																								.error
																						: props.meta
																									.submitError
																							? props
																									.meta
																									.submitError
																							: null
																				}
																			/>
																		);
																	}}
																</Field>
															</div>

															<Field name="message">
																{(props) => {
																	return (
																		<CustomTextField
																			{...props.input}
																			maxLength={400}
																			label={"Message"}
																			maxRows={3}
																			minRows={1}
																			isTextArea={true}
																			value={
																				props.input.value
																			}
																			onChange={
																				props.input.onChange
																			}
																			// placeholder={"Message"}
																			error={
																				props.meta.error &&
																				props.meta.touched
																					? props.meta
																							.error
																					: props.meta
																								.submitError
																						? props.meta
																								.submitError
																						: null
																			}
																		/>
																	);
																}}
															</Field>

															<div
																className={styles.btnMainContainer}
															>
																<Buttons
																	type={ButtonStyles.PRIMARY}
																	loading={loading}
																	disabled={
																		pristine ||
																		loading ||
																		hasValidationErrors
																	}
																>
																	<div
																		className={
																			styles.btnContainer
																		}
																	>
																		Submit
																	</div>
																</Buttons>
															</div>
														</div>

														<ReCAPTCHA
															ref={recaptchaRef}
															size="invisible"
															sitekey={process.env.REACT_APP_SITE_KEY}
															onChange={onCaptchaChange}
														/>
													</form>
												</>
											);
										}}
									/>
								</div>
							</div>

							<div className={styles.closeBtnContainer}>
								<div
									className={styles.closeBtn}
									onClick={() => setOpenModal(false)}
								>
									<CloseIcon />
								</div>
							</div>
						</div>
					</CustomDialog>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
